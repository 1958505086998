import React from "react";
import Layout from "../../Components/layout";
import GetItems from "../../Components/getItems";
import { Seo } from "../../Components/seo";


export default function Joggers() {
  return (
    <Layout>
      <GetItems search="Men’s Joggers" title="Men's Joggers" />
    </Layout>
  )
}
export const Head = () => (<Seo pathname={'./mens/joggers'} title="STM Quality Men's Joggers: Positivity and Awareness" description={"Shopping for men's joggers to spread positivity and awareness and promote Stronger Than Medicine (STM)."} />)